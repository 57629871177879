import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileImageUpload from "./ProfileImageUpload";
import { EditIcon } from "../../assets/icons/Icons";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import QuizIcon from "@mui/icons-material/Quiz";
import TollIcon from "@mui/icons-material/Toll";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import useUser from "../../hooks/useUser";
import useSettings from "../../hooks/useSettings";
import { useDispatch } from "react-redux";
import {
  ResetUpdateUserProfile,
  fetchProfileLearningStatistics,
  getStatesByCountryId,
  loadUserProfileDetails,
  updateUserProfile,
} from "../../redux/settings/settingsActions";
import EditProfile from "./Modal/EditProfile";
import WcIcon from "@mui/icons-material/Wc";
import { useNotifications } from "../notifications/notifications";

import moment from "moment";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
import { fetchProfileDetailsAction } from "../../redux/auth/authActions";
import useSubscription from "../../hooks/useSubscription";
dayjs.extend(utc); // Extend dayjs with UTC plugin

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  borderColor: "#919191",
  color: "#919191",
  "&:hover": {
    borderColor: "#919191",
  },
}));

function convertImageUrlToBase64(imageUrl) {
  return fetch(imageUrl)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}
const General = () => {
  const [hovered, setHovered] = useState(false);
  const [image, setImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isPremium, setIsPremium] = React.useState(false);
  const [activeId, setActiveId] = React.useState("");
  const [values, setValues] = useState({ label: "", value: 0 });
  const [stateValues, setStateValues] = useState({ label: "", value: 0 });

  const { checkSub } = useSubscription();


  const [updatedImgToSend, setUpdatedImgToSend] = React.useState("");
  const {
    userInfo: { profileId, isTrial },
  } = useUser();

  useEffect(() => {
    if (checkSub?.msg === "InActive") {
      setIsPremium(false);
    } else {
      setIsPremium(true);
    }
  }, []);
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleHover = () => {
    setHovered(true);
  };

  const handleUnhover = () => {
    setHovered(false);
  };
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleImageUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setIsLoading(false);
        const base64String = reader.result.split(",")[1];
        setFormValues((prevValues) => ({
          ...prevValues,
          passport: base64String,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const {
    isProfileUpdated,
    profileUpdatedSuccessMsg,
    isProfileUpdatedFired,
    learningStatistics,
    learningStatisticsLoading,
    learningStatisticsLoaded,
  } = useSettings();
  const dispatch = useDispatch();
  const [Toast, setToast] = useNotifications();
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState("");
  const handleClickOpen = (nav) => {
    setOpen(true);
    setNav(nav);
  };
  const handleClose = () => {
    setOpen(false);
    setFormClosed(true);
  };

  const { userProfileLoaded, userProfile, updateProfileState } = useSettings();
  const [user, setUser] = useState(userProfile ?? {});
  useEffect(() => {
    if (profileId) {
      // dispatch(fetchProfileDetailsAction(profileId));
      dispatch(fetchProfileLearningStatistics(profileId));
    }
  }, [profileId]);

  useEffect(() => {
    if (Object.keys(userProfile)?.length !== 0) {
      setUser(userProfile);
    }
  }, [userProfile]);

  const initialFormValues = {
    profileId: "",
    surname: "",
    firstName: "",
    otherName: "",
    dateOfBirth: dayjs(),
    email: "",
    gender: "",
    gsmNumber: "",
    passport: "",
    countryId: 0,
    stateId: 0,
  };
  const [formValues, setFormValues] = React.useState({ ...initialFormValues });
  const [formClosed, setFormClosed] = useState(false);
  const [editDob, setEditDOB] = useState(false);

  const handler = (newValue) => {
    setEditDOB(true);
    setFormValues((prevValues) => ({
      ...prevValues,
      dateOfBirth: newValue,
    }));
  };

  const updateValue = (value) => {
    setFormValues({
      ...formValues,
      countryId: value.value,
    });
    setValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };

  const updateStateValue = (value) => {
    setFormValues({
      ...formValues,
      stateId: value.value,
    });
    setStateValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };

  const stateByCountryFailure = (res) => {
    if (res !== 1) {
      setToast({
        isOpen: true,
        message: "An error occured!",
        severity: "error",
      });
    }
  };

  const countryHandler = (event, val) => {
    if (event) {
      setStateValues({ label: "", value: 0 });
      setFormValues({
        ...formValues,
        countryId: val.value,
      });
      dispatch(getStatesByCountryId(val?.value, stateByCountryFailure));
    }
  };

  const stateHandler = (event, val) => {
    if (event) {
      setFormValues({
        ...formValues,
        stateId: val.value,
      });
    }
  };
  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name == 'gsmNumber') {
      const regex = /^[0-9+]{0,15}$/; // Regex to allow only numbers, + and max 15 digits
      if (!regex.test(value)) {
        return;
      }
    }
    let newValue = value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };
  useEffect(() => {
    if (Object.keys(user)?.length !== 0) {
      setFormValues({
        ...formValues,
        profileId: profileId ?? "",
        surname: user?.surname ?? "",
        firstName: user?.firstName ?? "",
        otherName: user?.otherName ?? "",
        dateOfBirth: user?.dateOfBirth ?? dayjs(),
        email: user?.email ?? "",
        gender: user?.gender ?? "",
        gsmNumber: user?.gsmNumber ?? "",
        passport: user?.profilePic ?? "",
        countryId: user?.countryId ?? "",
        stateId: user?.stateId ?? "",
      });
      setValues({
        ...values,
        label: user?.countryName ?? "",
        value: user?.countryId ?? "",
      });
      setStateValues({
        ...values,
        label: user?.stateName ?? "",
        value: user?.stateId ?? "",
      });
    }
  }, [user]);

  const onSuccess = (res) => {
    if (res === 1) {
      handleClose();
      setToast({
        isOpen: true,
        message: "Profile updated successfully",
        severity: "success",
      });
      dispatch(ResetUpdateUserProfile());
      dispatch(fetchProfileDetailsAction(profileId));
      setFormValues(initialFormValues);
      setEditDOB(false);
      setOpenModal(false);
    }
  };
  const handleSubmit = () => {
    if (formValues) {
      let data = {};
      if (editDob) {
        data = { ...formValues, dateOfBirth: formValues?.dateOfBirth?.utc(true)?.format() }
      } else {
        data = { ...formValues }
      }
      dispatch(updateUserProfile(data, onSuccess));
    }
  };

  const handleAvatarClick = (e, id) => {
    setActiveId(id);
    if (e) {
      if (!isPremium && id !== 1) {
        //this will be handled later for a free and premium user
      } else {
        const imageUrl = e?.target?.currentSrc;
        if (imageUrl) {
          convertImageUrlToBase64(imageUrl)
            .then((base64String) => {
              setFormValues((prevValues) => ({
                ...prevValues,
                passport: base64String?.split(",")[1],
              }));
            })
            .catch((error) => {
              console.error("Error converting image to base64:", error);
            });
        }
      }
    }
  };
  const handleUpdateProfileImage = () => {
    if (value === 0) {
      dispatch(updateUserProfile(formValues, onSuccess));
    } else {
      dispatch(updateUserProfile(formValues, onSuccess));
    }
  };
  const [value, setValue] = React.useState(0);
  const handleChangeImg = (event, newValue) => {
    setValue(newValue);
  };

  const address = (user?.stateName ? (user?.stateName + ', ') : '') + (user?.countryName ?? 'N/A') + ".";
  const ProfileInfo = [
    {
      label: "Personal Information",
      isEditable: true,
      content: [
        {
          label: "Email Address",
          value: userProfileLoaded ? (
            user?.email ?? "Not updated"
          ) : (
            <Skeleton width="100px" height="20px" />
          ),
          icon: <EmailIcon sx={{ fontSize: "20px" }} />,
        },
        {
          label: "Phone Number",
          value: userProfileLoaded ? (
            (user?.gsmNumber === "" ? "Not updated" : user?.gsmNumber) ??
            "Not updated"
          ) : (
            <Skeleton width="100px" height="20px" />
          ),

          icon: <PhoneIcon sx={{ fontSize: "20px" }} />,
        },
        {
          label: "Address",
          value: userProfileLoaded ? (
            address ?? "Not Updated") : (
            <Skeleton />
          ),
          icon: <LocationOnIcon sx={{ fontSize: "20px" }} />,
        },
        {
          label: "Date of Birth",
          value: userProfileLoaded ? (
            (user?.dateOfBirth && moment(user?.dateOfBirth).format("MMMM Do, YYYY")) ?? "Not updated"
          ) : (
            <Skeleton width="100px" height="20px" />
          ),

          icon: <CalendarMonthIcon sx={{ fontSize: "20px" }} />,
        },
        {
          label: "Gender",
          value: userProfileLoaded ? (
            user?.gender ?? "Not updated"
          ) : (
            <Skeleton width="100px" height="20px" />
          ),
          icon: <WcIcon sx={{ fontSize: "20px" }} />,
        },
      ],
    },
    // {
    //   label: "Learning Statistics",
    //   isEditable: false,
    //   content: [
    //     {
    //       label: "Total Study Time",
    //       value: learningStatisticsLoading ? (
    //         <Skeleton width="100px" height="20px" />
    //       ) : (
    //         learningStatistics?.totalStudyTime
    //       ),
    //       icon: <AccessTimeFilledIcon sx={{ fontSize: "20px" }} />,
    //     },
    //     {
    //       label: "Test Completed",
    //       value: learningStatisticsLoading ? (
    //         <Skeleton width="100px" height="20px" />
    //       ) : (
    //         learningStatistics?.completedTest
    //       ),
    //       icon: <QuizIcon sx={{ fontSize: "20px" }} />,
    //     },
    //     {
    //       label: "Points Earned",
    //       value: learningStatisticsLoading ? (
    //         <Skeleton width="100px" height="20px" />
    //       ) : (
    //         learningStatistics?.pointsEarned
    //       ),
    //       icon: <TollIcon sx={{ fontSize: "20px" }} />,
    //     },
    //     {
    //       label: "No of Badges",
    //       value: learningStatisticsLoading ? (
    //         <Skeleton width="100px" height="20px" />
    //       ) : (
    //         learningStatistics?.noOfBadges
    //       ),
    //       icon: <MilitaryTechIcon sx={{ fontSize: "20px" }} />,
    //     },
    //     {
    //       label: "Level",
    //       /*  value: "Boss 💪", */
    //       value: learningStatisticsLoading ? (
    //         <Skeleton width="100px" height="20px" />
    //       ) : (
    //         learningStatistics?.level
    //       ),
    //       icon: <MilitaryTechIcon sx={{ fontSize: "20px" }} />,
    //     },
    //   ],
    // },
  ];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" fontWeight={600} color={"#313131"}>
              My Profile
            </Typography>
          </Box>

          <Divider />
          <Box
            p={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} alignItems={"center"} gap={3}>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <ProfileImageUpload
                  open={openModal}
                  formValues={formValues}
                  handleImageUpload={handleImageUpload}
                  handleHover={handleHover}
                  handleUnhover={handleUnhover}
                  handleClickOpen={handleClickOpenModal}
                  image={image}
                  handleClose={handleCloseModal}
                  isPremium={isPremium}
                  activeId={activeId}
                  handleAvatarClick={handleAvatarClick}
                  updatedImgToSend={updatedImgToSend}
                  handleUpdateProfileImage={handleUpdateProfileImage}
                  value={value}
                  handleChange={handleChangeImg}
                  isLoading={isLoading}
                  selectedImage={selectedImage}
                />
                <Box>
                  <Typography fontWeight={900}>
                    {userProfileLoaded ? (
                      user?.surname + " " + user?.firstName
                    ) : (
                      <Skeleton width="100px" height="40px" />
                    )}
                  </Typography>
                  {userProfileLoaded ? (
                    <Typography>
                      <span style={{ fontWeight: 500, color: "#313131" }}>
                        {user?.categoryName + "." ?? ""}
                      </span>{" "}
                      <span style={{ color: "#797979" }}>
                        {user?.pathName ?? ""}
                      </span>
                    </Typography>
                  ) : (
                    <Skeleton width="100px" height="40px" />
                  )}
                </Box>
              </Box>
              <StyledButton
                onClick={(e) => handleClickOpen("name")}
                variant="outlined"
                startIcon={<EditIcon />}
              >
                Edit
              </StyledButton>
            </Box>
          </Box>
          <Divider />
          {ProfileInfo?.map((item) => (
            <>
              <Box p={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography>{item?.label}</Typography>
                  {item?.isEditable && (
                    <StyledButton
                      onClick={(e) => handleClickOpen("details")}
                      variant="outlined"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </StyledButton>
                  )}
                </Box>
                <Grid container spacing={2}>
                  {item?.content?.map((item, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Paper sx={{ pt: 3 }}>
                        <Box display="flex" gap={2} alignItems="center">
                          <Box>
                            <IconButton
                              sx={{
                                backgroundColor: "#F4F4F4",
                                color: "#313131",
                              }}
                            >
                              {item?.icon}
                            </IconButton>
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              color="#919191"
                              fontSize="12px"
                            >
                              {item?.label}
                            </Typography>
                            <Typography variant="subtitle1">
                              {item?.value}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Divider />
            </>
          ))}
        </Paper>
      </Grid>
      <EditProfile
        handleSubmit={handleSubmit}
        nav={nav}
        open={open}
        handleClose={handleClose}
        formValues={formValues}
        handleChange={handleChange}
        isLoading={isProfileUpdatedFired}
        handler={handler}
        countryHandler={countryHandler}
        updateValue={updateValue}
        values={values}
        updateStateValue={updateStateValue}
        stateHandler={stateHandler}
        stateValues={stateValues}
      />
      <Toast />
    </Grid>
  );
};

export default General;
