import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import DataTable from "../../../components/DataTable/DataTable";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { MoreIcon } from "../../../assets/icons/Icons";
import AvatarWithText from "../../../components/Avatar/AvatarWithText";
import CustomizedDialogs from "../../../components/StudentManagement/Dialog";
import { Menu, MenuItem } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch } from "react-redux";
import { resendMail } from "../../../redux/auth/authActions";
import { managestudentResendMail } from "../../../redux/school";
import useUser from "../../../hooks/useUser";
import EditStudentForm from "../../../components/StudentManagement/EditStudentForm";
import useSchool from "../../../hooks/useSchool";
import { Button } from "reactstrap";

export default function SponsorStudentsTable({
  open,
  handleClose,
  handleOpen,
  modalContent,
  allStudentBySponorById,
  setToastState,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEl, setOpenEl] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const school = useSchool();
  const { studentById } = school;
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const onSuccess = (res) => {
    setToastState({ isOpen: true, severity: "success", message: res.msg });
  };
  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
  };

  // function that  holds the action button
  const handleClick = (rowData, event) => {
    if (rowData && rowData.length > 0) {
      setSelectedRowData(rowData);
      setAnchorEl(event.currentTarget);
      setOpenEl(true);
    }
  };

  //function to close the table menu
  const handleCloseEl = () => {
    setAnchorEl(null);
    setOpenEl(false);
  };

  // function that resend mail on click of resend  mail
  const handleResendEmail = () => {
    // setLoading(false);
    const payload = {
      SchoolId: userInfo.schoolId,
      SponsorId: userInfo.sponsorId,
    };
    if (selectedRowData) {
      const email = selectedRowData[1];
      dispatch(managestudentResendMail(email, payload, onSuccess, onFail));
    }
  };

  const handleEdit = () => {
    setOpenEditDialog(true);
    setOpenEl(false);
  };

  const closeModal = () => {
    setOpenEditDialog(false);
  };
  const TableData = ({}) => {
    return (
      <div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={openEl}
          onClose={handleCloseEl}
          MenuListProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={handleEdit}>
            <Edit color="primary" /> Edit
          </MenuItem>
          <MenuItem onClick={handleResendEmail}>
            <EmailIcon color="green" /> Resend Email
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const columns = [
    {
      name: "FullName",
      label: "Student",
      options: {
        customBodyRender: (value, tableMeta) => {
          const email = tableMeta.rowData[1];
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <AvatarWithText text={value} email={email} />
            </div>
          );
        },
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        display: false,
      },
    },
    {
      name: "GSMNumber",
      label: "Phone Number",
      options: {
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    // {
    //   name: "Gender",
    //   label: "Gender",
    //   options: {
    //     setCellProps: () => ({ style: { minWidth: "130px", padding: "20px" } }),
    //   },
    // },
    // {
    //   name: "ClassName",
    //   label: "Class",
    //   options: {
    //     setCellProps: () => ({ style: { minWidth: "100px", padding: "20px" } }),
    //   },
    // },
    {
      name: "Status",
      label: "Status",
      options: {
        setCellProps: () => ({ style: { minWidth: "130px", padding: "20px" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <Button>
              <b
                style={{
                  color: value === "Verified" ? "green" : "red",
                }}
              >
                {value}
              </b>
            </Button>
          );
        },
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Tooltip title={"View more details"}>
              <IconButton
                size="small"
                onClick={(event) => handleClick(tableMeta.rowData, event)}
              >
                <MoreIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];
  return (
    <>
      <DataTable data={allStudentBySponorById} columns={columns} />
      <TableData allStudentBySponorById={allStudentBySponorById} />
      <EditStudentForm
        rowData={selectedRowData}
        openEditDialog={openEditDialog}
        studentById={studentById}
        closeModal={closeModal}
      />
    </>
  );
}
