import Axios from "../../connection/defaultClient";
import {
  GET_LEADERBOARD_FAIL,
  GET_LEADERBOARD_LOADING,
  GET_LEADERBOARD_SUCCESS,
} from "./types";

// GetLeaderboard
export const getLeaderboardByLocation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_LEADERBOARD_LOADING,
      payload: true,
    });
    Axios.get(`/Gamification/Leaderboard`, { params: payload })
      .then((response) => {
        dispatch({
          type: GET_LEADERBOARD_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LEADERBOARD_FAIL,
          payload: error.message,
        });
      });
  };
};
