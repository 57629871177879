import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "../../../components/DataTable/DataTable";
import { MoreIcon } from "../../../assets/icons/Icons";
import AvatarWithText from "../../../components/Avatar/AvatarWithText";
import { Button } from "reactstrap";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch } from "react-redux";
import { resendMail } from "../../../redux/auth/authActions";
import EditStudentForm from "../../../components/StudentManagement/EditStudentForm";
import {
  fetchGetStudentByProfileId,
  managestudentResendMail,
} from "../../../redux/school";
import useSchool from "../../../hooks/useSchool";
import { RESEND_STUDENT_MAIL } from "../../../redux/school/types";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/AuthReuseables/Preloader/Loader";

export default function StudentsTable({
  student,
  setToastState,
  modalContent,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEl, setOpenEl] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const dispatch = useDispatch();
  const school = useSchool();
  const { studentById } = school;
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const { userInfo } = useUser();
  const { schoolId, sponsorId } = userInfo;
  const [loading, setLoading] = React.useState(false);

  const onSuccess = (res) => {
    setToastState({ isOpen: true, severity: "success", message: res.msg });
  };
  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
  };

  // function that  holds the action button
  const handleClick = (rowData, event) => {
    if (rowData && rowData.length > 0) {
      setSelectedRowData(rowData);
      setAnchorEl(event.currentTarget);
      setOpenEl(true);
    }
  };

  //function to close the table menu
  const handleCloseEl = () => {
    setAnchorEl(null);
    setOpenEl(false);
  };

  // function that resend mail on click of resend  mail
  const handleResendEmail = () => {
    setLoading(false);
    const payload = {
      SchoolId: userInfo.schoolId,
      SponsorId: userInfo.sponsorId,
    };
    if (selectedRowData) {
      const email = selectedRowData[1];
      dispatch(managestudentResendMail(email, payload, onSuccess, onFail));
    }
  };

  const handleEdit = () => {
    setOpenEditDialog(true);
    setOpenEl(false);
  };

  const closeModal = () => {
    setOpenEditDialog(false);
  };

  //TableData that carries the menu item
  const TableData = () => {
    return (
      <div>
        {loading && <CircularProgress />}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={openEl}
          onClose={handleCloseEl}
          MenuListProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={handleEdit}>
            <Edit color="primary" /> Edit
          </MenuItem>
          <MenuItem onClick={handleResendEmail}>
            <EmailIcon color="green" /> Resend Email
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const columns = [
    {
      name: "FullName",
      label: "Student",
      options: {
        customBodyRender: (value, tableMeta) => {
          const email = tableMeta.rowData[1];
          return <AvatarWithText text={value} email={email} />;
        },
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        display: false,
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    {
      name: "FirstName",
      label: "FirstName",
      options: {
        display: false,
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    {
      name: "Surname",
      label: "Surname",
      options: {
        display: false,
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    {
      name: "OtherName",
      label: "OtherName",
      options: {
        display: false,
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    {
      name: "GSMNumber",
      label: "Phone Number",
      options: {
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    // {
    //   name: "gender",
    //   label: "Gender",
    //   options: {
    //     setCellProps: () => ({ style: { minWidth: "130px", padding: "20px" } }),
    //   },
    // },
    // {
    //   name: "ClassName",
    //   label: "Class",
    //   options: {
    //     setCellProps: () => ({ style: { minWidth: "100px", padding: "20px" } }),
    //   },
    // },
    {
      name: "Status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Button>
              <b
                style={{
                  color: value === "Verified" ? "green" : "red",
                }}
              >
                {value}
              </b>
            </Button>
          );
        },
      },
    },

    {
      name: "ProfileId",
      label: "Action",
      options: {
        customBodyRender: (rowData, tableMeta) => {
          // const RowData= student.find(datum => datum.ProfileId === rowData)
          return (
            <Tooltip title={"View more details"}>
              <IconButton
                size="small"
                onClick={(event) => handleClick(tableMeta.rowData, event)}
              >
                <MoreIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];

  return (
    <>
      <DataTable data={student} columns={columns} count={10} page={10} />
      <TableData student={student} />
      <EditStudentForm
        rowData={selectedRowData}
        openEditDialog={openEditDialog}
        studentById={studentById}
        closeModal={closeModal}
      />
    </>
  );
}
