import React, { useEffect, useRef, useState } from "react";
import WeeklyTriviaDialog from "./WeeklyTriviaDialog";
import PostTrivia from "../TriviaDialogs/PostTrivia";
import useDashboard from "../../../hooks/useDashboard";
import { convertAnswerToAlphabet } from "../../../helpers/helper";
// import PostTriviaFail from "../TriviaDialogs/PostTriviaFail";

// const theTestDatas = [
//   // { totalTrivaNumber: 5 },
//   {
//     number: 1,
//     question: "Solve for 'x' : 2x + 5 = 11",
//     options: [
//       {
//         id: 0,
//         option: "3",
//         flag: true,
//       },
//       {
//         id: 1,
//         option: "12",
//         flag: false,
//       },
//       {
//         id: 2,
//         option: "6",
//         flag: false,
//       },
//       {
//         id: 3,
//         option: "-2",
//         flag: false,
//       },
//     ],
//     selectedIndex: null,
//   },
//   {
//     number: 2,
//     question:
//       "What was the first captial city and who was the first president of Nigeria",
//     options: [
//       {
//         id: 0,
//         option: "Lagos and Sanni Abacha",
//         flag: false,
//       },
//       {
//         id: 1,
//         option: "Calabar and Tafawa Balewa",
//         flag: false,
//       },
//       {
//         id: 2,
//         option: "Abuja and Olusegun Obasanjo",
//         flag: false,
//       },
//       {
//         id: 3,
//         option: "Lagos and Nnamdi Azikiwe",
//         flag: true,
//       },
//     ],
//     selectedIndex: null,
//   },
//   {
//     number: 3,
//     question: (
//       <p>
//         Choose the one that is most nearly opposite in meaning to the underlined
//         word.
//         <br />
//         <br />
//         The ignorant miner found a <u>priceless</u> diamond but thought it was a
//         _____________ material
//       </p>
//     ),
//     options: [
//       {
//         id: 0,
//         option: "Worthless",
//         flag: true,
//       },
//       {
//         id: 1,
//         option: "Valuable",
//         flag: false,
//       },
//       {
//         id: 2,
//         option: "Durable",
//         flag: false,
//       },
//       {
//         id: 3,
//         option: "Costly",
//         flag: false,
//       },
//     ],
//     selectedIndex: null,
//   },
//   {
//     number: 4,
//     question: "Who is referred to as the father of computing?",
//     options: [
//       {
//         id: 0,
//         option: "Ada Lovelace",
//         flag: false,
//       },
//       {
//         id: 1,
//         option: "Gottfried Wilhelm Leibniz",
//         flag: false,
//       },
//       {
//         id: 2,
//         option: "Charles Babbage",
//         flag: true,
//       },
//       {
//         id: 3,
//         option: "Isaac Newton",
//         flag: false,
//       },
//     ],
//     selectedIndex: null,
//   },
//   {
//     number: 5,
//     question: "What is known as the powerhouse of the cell?",
//     options: [
//       {
//         id: 0,
//         option: "Chlorophyll",
//         flag: false,
//       },
//       {
//         id: 1,
//         option: "Mitochondria",
//         flag: true,
//       },
//       {
//         id: 2,
//         option: "Ribosomes",
//         flag: false,
//       },
//       {
//         id: 3,
//         option: "Nucleus",
//         flag: false,
//       },
//     ],
//     selectedIndex: null,
//   },
// ];

const WeeklyTrivia = ({
  open,
  handleClose,
  // openPostTriviaDialog,
  goBack,
  // handleOpenPostTriviaDialog,
  // handleClosePostTriviaDialog,
  // handleCloseFix,
}) => {
  const [optionss, setOptions] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [currentNum, setCurrentNum] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [secondChance, setSecondChance] = useState(false);
  const [points, setPoints] = useState(0)
  const [openPostTriviaDialog, setOpenPostTriviaDialog] = useState(false);
  const { weeklyTrivia } = useDashboard()
  const [testData, setTestData] = useState([]);
  const [openFailedTrivia, setOpenFailedTrivia] = useState(false)

  const totalTrivaNumber = weeklyTrivia?.data?.length;

  const totalDuration = 28;

  // const duration = 15;
  const audioRef = useRef(null);
  const correctRef = useRef(null);
  const wrongRef = useRef(null);
  const timerx2Ref = useRef(null);


  useEffect(() => {

    if (weeklyTrivia?.data?.length > 0) {
      setTestData(weeklyTrivia?.data);
    }

  }, [testData]);

  const handleClosePostTriviaDialog = () => {
    setOpenPostTriviaDialog(false)
  }




  const handleSubmit = () => {
    setIsSubmitted(true);
    if (wrongAnswers.length >= 1) {
      setOpenPostTriviaDialog(true)
    } else {
      handleClose();
    }
  }

  // data[0].choices[0].option
  // useEffect(() => {
  //   ;
  //   if (secondChance) {
  //     const wrongQuestionsData = theTestData
  //       .slice(1)
  //       .filter((_, index) => wrongAnswers.includes(index + 1));
  //     setTestData(wrongQuestionsData);
  //   }
  // }, [secondChance]);

  // useEffect(() => {

  //   if (
  //     testData.length > 0 &&
  //     testData[0]?.totalTrivaNumber &&
  //     currentNum === testData[0].totalTrivaNumber - 1 &&
  //     timeLeft <= 0
  //   ) {
  //     // handleSubmit();
  //   }
  // }, [currentNum, testData, timeLeft]);

  const handlePlayButtonClick = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      // Pause the audio if it's currently playing
      if (!audioElement.paused) {
        audioElement.pause();
      }
      // Set the currentTime to 0 to start from the beginning
      audioElement.currentTime = 0;
      audioElement.play();
    }
  };

  const handleStopTickSound = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };
  const handleCorrectClick = () => {
    const audioElement = correctRef.current;
    if (audioElement) {
      audioElement.play();
    }
  };

  const handleWrongClick = () => {
    const audioElement = wrongRef.current;
    if (audioElement) {
      audioElement.play();
    }
  };

  const handleTimerx2 = () => {
    const audioElement = timerx2Ref.current;
    if (audioElement) {
      audioElement.play();
    }
    audioElement.currentTime = 0;
    setTimeLeft((timeLeft) => timeLeft * 2);
  };
  const updateOptions = (newArray) => {
    setOptions(newArray);
  };
  const handleTimeLeft = (timeLeft) => {
    setTimeLeft(timeLeft - 1);
  };


  // Assuming testData is an array where each item contains a 'choices' array
  const options = testData?.[currentNum]?.choices?.map((choice, index) => {
    return {
      optionText: choice?.option, // The actual option text or HTML
      optionNo: choice?.optionNo,  // The option number (1, 2, 3, etc.)
      index: index,
      bgcolor: null,              // To track the index of the option
    };
  });

  const handleAnswerSelect = (selectedOption, index) => {
    // debugger
    if (selectedButton === null) {
      const correctAnswer = testData[currentNum]?.answer;
      const convertedAnswer = convertAnswerToAlphabet(selectedOption);
      // debugger
      const isCorrect = convertedAnswer === correctAnswer;

      const modifiedOptions = options.map((option, i) => ({
        ...option,
        bgcolor: i === index ? (isCorrect ? "#008000" : "#FF0000") : (option.bgcolor || null)
      }));

      if (!isCorrect) {
        setWrongAnswers(prev => [...prev, currentNum]);
        handleWrongClick();
      } else {
        setPoints(prevPoints => prevPoints + 10)
        handleCorrectClick();
      }

      setOptions(modifiedOptions);
      setSelectedButton(index);

      setTestData(prevData => {
        const newData = [...prevData];
        newData[currentNum] = {
          ...newData[currentNum],
          selectedIndex: index,
          selectedOption: selectedOption
        };
        return newData;
      });
    }
  };

  const handleCloseFix = () => {
    setOpenPostTriviaDialog(false)
    if (wrongAnswers.length >= 2) {
      setOpenFailedTrivia(true)
    } else {
      handleClose()
    }

  }

  // const handleAnswerSelect = (flag, index) => {
  //   if (selectedButton === null) {
  //     const modifiedOptions = [...options];
  //     if (flag) {
  //       modifiedOptions[index].bgcolor = "#008000";
  //       handleCorrectClick();
  //     } else {
  //       modifiedOptions[index].bgcolor = "#FF0000";
  //       handleWrongClick();
  //       let wrongIds = [...wrongAnswers, currentNum];
  //       setWrongAnswers(wrongIds);
  //       // setWrongAnswers((prevNum) => prevNum + 1);
  //     }
  //     setOptions(modifiedOptions);
  //     setSelectedButton(index);
  //     testData[currentNum].selectedIndex = index;
  //     console.log(wrongAnswers, "wrong");
  //   }
  // };


  const handleNextQuestion = () => {

    if (currentNum < totalTrivaNumber - 1) {
      setCurrentNum((prevNum) => prevNum + 1);
      setOptions([]);
      setSelectedButton(null);
      setTimeLeft(30);

      // Log the state changes
      // console.log("Moving to next question. New states:");
      // console.log("currentNum:", currentNum + 1);
      // console.log("selectedButton:", null);

      // If you're using any audio, uncomment this line
      // handlePlayButtonClick();
    } else {
      // Handle the end of the quiz
      console.log("Quiz completed");
      // Uncomment and implement handleSubmit if needed
      // handleSubmit();
    }
  };
  // const handleNextQuestion = (timer) => {
  //   if (currentNum === totalTrivaNumber - 1) {
  //     if (wrongAnswers.length === 0) {
  //       /*   handleSubmit(); */
  //     }
  //   } else {
  //     // handlePlayButtonClick()
  //     setCurrentNum((prevNum) => prevNum + 1);
  //     setOptions([]);
  //     setSelectedButton(null);
  //     setTimeLeft(duration);
  //   }

  // };
  // const handleSubmit = () => {
  //   debugger
  //   setIsSubmitted(true);
  //   if (wrongAnswers.length >= 1) {
  //     handleOpenPostTriviaDialog()
  //     // handleClose();
  //   } else {
  //     // handleOpenPostTriviaDialog(true);
  //     // handleClosePostTriviaDialog(true)
  //     handleClose();
  //   }
  // };

  const handleGoBack = () => {
    setOptions([]);
    handleClosePostTriviaDialog();
    setTimeLeft(30);
    setCurrentNum((prevNum) => prevNum - prevNum + 1); //working on this
    // setSecondChance(true);
    // setCurrentNum(0);
    // setTestData([]);
  };

  return (
    <>
      <WeeklyTriviaDialog
        open={!openPostTriviaDialog ? open : goBack}
        handleClose={handleClose}
        totalTrivaNumber={totalTrivaNumber}
        testData={testData}
        options={optionss}
        selectedButton={selectedButton}
        currentNum={currentNum}
        handleAnswerSelect={handleAnswerSelect}
        handleNextQuestion={handleNextQuestion}
        updateOptions={updateOptions}
        handleSubmit={handleSubmit}
        timeLeft={timeLeft}
        totalDuration={totalDuration}
        handleTimeLeft={handleTimeLeft}
        audioRef={audioRef}
        correctRef={correctRef}
        wrongRef={wrongRef}
        timerx2Ref={timerx2Ref}
        isSubmitted={isSubmitted}
        handleTimerx2={handleTimerx2}
        triviapoints={points}
      />
      <PostTrivia
        open={openPostTriviaDialog}
        handleClose={handleGoBack}
        handleCloseFix={handleCloseFix}
        wrongAnswers={wrongAnswers}
      />
      {/* <PostTriviaFail
        open={openPostTriviaDialog}
        handleCloseFix={handleCloseFix}
        handleClose={handleGoBack}
      /> */}
    </>
  );
};

export default WeeklyTrivia;
