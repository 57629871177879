import { useSelector } from "react-redux";

const useGamification = () => {
  const gamification = useSelector((state) => state.gamification);

  const { loading, gamificationData, error } = gamification;

  return {
    loading,
    gamificationData,
    error,
  };
};

export default useGamification;
