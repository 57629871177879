import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { ReactComponent as AllStudentsIcon } from "../../assets/images/AllStudentsIcon.svg";
import { ReactComponent as VerifiedStudents } from "../../assets/images/VerifiedStudentsIcon.svg";
import { ReactComponent as UnverifiedStudents } from "../../assets/images/UnverifiedStudentsIcon.svg";
import { ReactComponent as SchoolIcon } from "../../assets/images/SchoolIcon.svg";
import { useDispatch } from "react-redux";
import TotalAmountCard from "../../components/dashboards/sponsor/totalAmountCard";
import Cards from "../../components/dashboards/sponsor/schoolDashboardCards";
import SchoolPath from "../../components/dashboards/sponsor/schoolPaths/schoolPath";
import StudentBeneficiaries from "../../components/dashboards/sponsor/StudentBeneficiaries";
import {
  fetchSponsorBeneficiaries,
  fetchSponsorDashboardStat,
  fetchSponsorStudentsPath,
} from "../../redux/dashboard/dashboardActions";
import useUser from "../../hooks/useUser";
import useDashboard from "../../hooks/useDashboard";
import useSubscription from "../../hooks/useSubscription";
import { fetchSponsorSubscriptions } from "../../redux/subscriptions";

function SponsorDashboard() {
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { sponsorSub, loading } = useSubscription();
  const {
    sponsorDashboardStat,
    isLoadingSponsorStat,
    sponsorStudentsPath,
    isLoadingSponsorStudentsPath,
    sponsorBeneficiaries,
    isLoadingSponsorBeneficiaries,
  } = useDashboard();
  const [currentDate, setCurrentDate] = useState(moment());

  useEffect(() => {
    const sponsorId = userInfo?.sponsorId;
    if (sponsorId) {
      dispatch(fetchSponsorDashboardStat(sponsorId));
      dispatch(fetchSponsorStudentsPath(sponsorId));
      dispatch(fetchSponsorBeneficiaries(sponsorId));
      dispatch(fetchSponsorSubscriptions(sponsorId));
    }

    const intervalId = setInterval(() => {
      setCurrentDate(moment());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [userInfo]);

  let cards = [
    {
      icon: <AllStudentsIcon />,
      text: "All Students Enrolled",
      total: sponsorDashboardStat?.studentsEnrolled ?? 0,
      loading: isLoadingSponsorStat,
    },
    {
      icon: <VerifiedStudents />,
      text: "Verified Students",
      total: sponsorDashboardStat?.studentsVerified ?? 0,
      loading: isLoadingSponsorStat,
    },
    {
      icon: <UnverifiedStudents />,
      text: "Unverified Students",
      total: sponsorDashboardStat?.unverifyStudents ?? 0,
      loading: isLoadingSponsorStat,
    },
    {
      icon: <SchoolIcon />,
      text: "School",
      total: sponsorDashboardStat?.schools ?? 0,
      loading: isLoadingSponsorStat,
    },
  ];
  console.log(sponsorBeneficiaries, "sponsorBeneficiaries");
  return (
    <>
      <Container sx={{ padding: { xs: "0px" } }}>
        <Box component={Stack} gap={3} padding={0} bgcolor={"#FAFAFB"}>
          <Box>
            <Typography color={"#45464E"} fontSize={"16px"} fontWeight={600}>
              Dashboard
            </Typography>
            <Typography
              color={"#45464E"}
              fontSize={"14px"}
            >{`Today is ${currentDate.format(
              "dddd, D MMMM YYYY"
            )}`}</Typography>
          </Box>

          <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
            position="relative"
          >
            <Box width={{ xs: "100%", md: "70%" }} height={"auto"}>
              <Cards cards={cards} />

              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} sm={12}>
                    {isLoadingSponsorStudentsPath ? (
                      <Skeleton
                        variant="rounded"
                        width="max-width"
                        height="300px"
                      />
                    ) : (
                      <SchoolPath studentsPath={sponsorStudentsPath} />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12} sm={12}>
                    {loading ? (
                      <Skeleton
                        variant="rounded"
                        width="max-width"
                        height="300px"
                      />
                    ) : (
                      <TotalAmountCard sponsorSub={sponsorSub} />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box width={{ xs: "100%", md: "30%" }} height={"auto"}>
              {isLoadingSponsorBeneficiaries ? (
                <Skeleton variant="rounded" width="max-width" height="500px" />
              ) : (
                <StudentBeneficiaries
                  sponsorBeneficiaries={sponsorBeneficiaries}
                />
              )}
            </Box>
          </Box>
          {/* <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={3}
            position="relative"
          >
            <Box width={{ xs: "100%", md: "70%" }} height={"auto"}>
          <StudentStudyTime />
            </Box>
            <Box width={{ xs: "100%", md: "30%" }} height={"auto"}>
          <TotalAmountCard />
            </Box>
          </Box> */}
        </Box>
      </Container>
    </>
  );
}

export default SponsorDashboard;
