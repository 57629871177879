import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Sidebar from "../components/sidebars/Sidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  SwipeableDrawer,
  Typography,
  alpha,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AccountPopover from "../components/dashboards/AccountPopover";
import NotificationsPopover from "../components/dashboards/NotificationsPopover";
import { NigeriaSvgIcon } from "../assets/icons/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SchoolIcon } from "../assets/images/SchoolIcon.svg";
import TotalPointsBox from "../components/dashboards/students/TotalPoints";
import TotalBadgesBox from "../components/dashboards/students/TotalBadges";
import TotalStreaksBox from "../components/dashboards/students/TotalStreaks";
import TotalDiamondsBox from "../components/dashboards/students/TotalDiamonds";
import missionbox from "../assets/images/missionbox2.gif";
import trivia from "../assets/images/trivia.svg";
import WeeklyMission from "../components/Modal/WeeklyMissions/WeeklyMission";
import WeeklyTrivia from "../components/Modal/WeeklyTrivia/WeeklyTrivia";
import { calculateTimeRemaining } from "../helpers/helper";
import QuizIcon from "@mui/icons-material/Quiz";
import Preloader from "../components/Preloader/Preloader";
import NetworkPanel from "../components/Network/NetworkPanel";
import { useDispatch } from "react-redux";
import useUser from "../hooks/useUser";
import useAdminUser from "../hooks/useAdminUser";
import {
  fetchProfileDetailsAction,
  logoutUserAction,
} from "../redux/auth/authActions";
import PremiumDialog from "../components/Modal/PremiumDialog";
import { CheckUserSub } from "../redux/subscriptions";
import useSubscription from "../hooks/useSubscription";
import AdminAccountPopover from "../components/dashboards/AdminAccountPopover";
import { fetchWeeklyTrivia } from "../redux/dashboard/dashboardActions";

const drawerWidth = 240;

const Main = styled("main", {
  shouldForwardProp: (prop) => !["open", "noPadding"].includes(prop),
})(
  // const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, noPadding }) => ({
    flexGrow: 1,
    padding: noPadding ? 0 : theme.spacing(3),
    backgroundColor: theme.palette.mode === "light" ? "#fafafb" : "#1a1a1a",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxShadow: "none",
  position: "fixed",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // background: 'white',
  color: "black",
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const noPaddingRoutes = ["/dashboard/leaderboard", "/dashboard/learning/study"];

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [drawer, setDrawer] = React.useState(false);
  const location = useLocation();
  const [loadding, setLoading] = React.useState(true);
  const [openPostTriviaDialog, setOpenPostTriviaDialog] = React.useState(false);
  const [goBack, setGoBack] = React.useState(false);
  const { userInfo, loadingLogout } = useUser();
  const navigate = useNavigate();
  const { loading } = useSubscription();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [paddingTopValue, setPaddingTopValue] = useState(null);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(CheckUserSub(userProfileId));
      dispatch(fetchProfileDetailsAction(userProfileId));
    }
    dispatch(fetchWeeklyTrivia());
  }, [userInfo?.profileId]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setPaddingTopValue(isOnline ? "0px" : "30px");
  }, [isOnline]);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const token = useUser()?.token ? useUser()?.token : useAdminUser()?.token;

  // React.useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Perform your logout or session cleanup logic here
  //     // For example, dispatch a logout action or call an API to invalidate the session
  //
  //     console.log("Logging out user...");
  //     dispatch(logoutUserAction(token));

  //     // Optionally show a confirmation message
  //     const confirmationMessage = "Are you sure you want to leave?";
  //     event.returnValue = confirmationMessage; // Standard for most browsers
  //     return confirmationMessage; // For some older browsers
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // State to manage the trial modal dialog
  const [trialModalOpen, setTrialModalOpen] = React.useState(false);

  // Function to open the trial modal dialog
  const openTrialModal = () => {
    setTrialModalOpen(true);
  };

  // Function to close the trial modal dialog
  const closeTrialModal = () => {
    setTrialModalOpen(false);
  };

  const [openMissions, setOpenMissions] = React.useState(false);
  const [openTrivia, setOpenTrivia] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOpenMissions = () => {
    setOpenMissions(true);
  };
  const handleClickOpenTrivia = () => {
    setOpenTrivia(true);
  };
  const handleCloseMissions = () => {
    setOpenMissions(false);
  };

  const handleCloseTrivia = () => {
    setOpenTrivia(false);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(value);
  };

  const [timeRemaining, setTimeRemaining] = React.useState(
    calculateTimeRemaining()
  );

  useEffect(() => {
    const targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 5);
    targetDate.setHours(targetDate.getHours() + 8);
    targetDate.setMinutes(targetDate.getMinutes() + 23);

    const intervalId = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining(targetDate);
      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining.total <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  //Prevent Copying of content

  // Uncomment this to disable copy and paste
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Prevent Ctrl+C (Cmd+C on Mac) shortcut
      if (event.ctrlKey || event.metaKey) {
        switch (String.fromCharCode(event.which).toLowerCase()) {
          case "c":
            event.preventDefault();
            break;
          default:
            break;
        }
      }
    };

    // Attach event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Detach event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const disableCopyPaste = () => {
    // Disable text selection
    document.onselectstart = function (e) {
      e.preventDefault();
      return false;
    };

    // Disable copy, cut, and paste events
    document.oncopy = function (e) {
      e.preventDefault();
      return false;
    };

    document.oncut = function (e) {
      e.preventDefault();
      return false;
    };

    document.onpaste = function (e) {
      e.preventDefault();
      return false;
    };

    // Disable print screen
    document.addEventListener("keydown", function (event) {
      if (event.key === "PrintScreen") {
        event.preventDefault();
        return false;
      }
    });
  };

  useEffect(() => {
    //Uncomment this to disable copy and paste
    disableCopyPaste();
  }, []);

  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  useEffect(() => {
    if (userInfo?.isOnboardComplete && userInfo?.isSchoolAdmin) {
      navigate("/school/dashboard");
    }

    if (userInfo?.isOnboardComplete && userInfo?.isSponsor) {
      navigate("/sponsor/dashboard");
    }
  }, [
    userInfo?.isOnboardComplete,
    userInfo?.isSchoolAdmin,
    userInfo?.isSponsor,
  ]);

  // useEffect(() => {
  //   const isOnTrial = userInfo?.isTrial;

  //   if (isOnTrial && location.pathname.startsWith("/dashboard/learning")) {
  //     navigate("/dashboard/home");
  //     openTrialModal();
  //   }
  // }, [userInfo, location.pathname]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <>
      {loading || loading || loadingLogout ? (
        <Preloader />
      ) : (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <div className="hidden lg:flex w-[231px]">
            <Sidebar
              open={open}
              setOpen={setOpen}
              openTrialModal={setTrialModalOpen}
            />
          </div>
          <AppBar
            position="fixed"
            open={open}
            className={`lg:ml-${open ? "0" : "240"} transition-all ease-in-out`}
          >
            <NetworkPanel />
            <Toolbar>
              <div className="flex justify-between items-center w-full">
                {/* <Button onClick={toggleDrawer(true)}>Hello</Button> */}
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon color="#40499D" />
                </IconButton>

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems={"center"}
                  sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    justifyContent: "space-between",
                  }}
                >
                  <div className="flex gap-2">
                    <div
                      className="flex gap-1 items-center justify-center cursor-pointer border px-2 py-1 rounded-[5px] bg-blue-100 hover:bg-e-study-bg-primary hover:text-white"
                      onClick={handleClickOpenMissions}
                    >
                      <img
                        src={missionbox}
                        alt="mission box"
                        className="w-[24px] h-[24px]"
                      />
                      <p className="text-[14px] font-medium">Weekly mission</p>
                    </div>

                    <div
                      className="flex gap-1 items-center justify-center cursor-pointer border px-2 py-1 rounded-[5px] bg-blue-100 hover:bg-e-study-bg-primary hover:text-white"
                      onClick={handleClickOpenTrivia}
                    >
                      <img
                        src={trivia}
                        alt="mission box"
                        className="w-[24px] h-[24px]"
                      />
                      <p className="text-[14px] text-medium">Weekly Trivia</p>
                    </div>
                  </div>

                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <div>
                      <div
                        onClick={toggleDropdown}
                        className="flex gap-1 items-center justify-center cursor-pointer border px-2 py-1 rounded-[5px] border-slate-200"
                      >
                        <p className="text-[14px]">View Points</p>
                        <ArrowDropDownIcon
                          style={{
                            color: "black",
                            transform: isOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>

                      {isOpen && (
                        <div className="absolute mt-2 w-48 bg-white border rounded-lg shadow-lg p-4">
                          <div className="flex flex-col gap-4">
                            <TotalBadgesBox />
                            <TotalPointsBox />
                            <TotalStreaksBox />
                            <TotalDiamondsBox />
                          </div>
                        </div>
                      )}
                    </div>

                    {userInfo?.studentSponsorName !== null &&
                      userInfo?.studentSchoolName !== null && (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow:
                              "0px 6.745px 36.42px 0px rgba(128, 144, 155, 0.05)",
                            border: "1px solid var(--Lightbg, #EAECFF)",
                            padding: "5px 10px",
                            borderRadius: "10px",
                          }}
                        >
                          {userInfo?.studentSchoolName !== null ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                mr: 1,
                              }}
                            >
                              <SchoolIcon />
                              <HtmlTooltip
                                title={userInfo?.studentSchoolName ?? "School"}
                              >
                                <Box>
                                  <h3
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      color: "#40499D",
                                    }}
                                  >
                                    {userInfo?.studentSchoolName
                                      ? truncateText(
                                          userInfo?.studentSchoolName,
                                          20
                                        )
                                      : truncateText("School", 20)}
                                  </h3>
                                  <p
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                      color: "#40499D",
                                      marginTop: "-3px",
                                    }}
                                  >
                                    School
                                  </p>
                                </Box>
                              </HtmlTooltip>
                            </Box>
                          ) : (
                            ""
                          )}

                          {userInfo?.studentSponsorName !== null &&
                            userInfo?.studentSchoolName !== null && (
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                              />
                            )}
                          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}

                          {userInfo?.studentSponsorName !== null ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                ml: 1,
                              }}
                            >
                              <Avatar
                                alt="Sponsor"
                                src={`${
                                  userInfo?.studentSponsorProfilePic ?? ""
                                }`}
                              />
                              <Box>
                                <h3
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    color: "#40499D",
                                  }}
                                >
                                  {userInfo?.studentSponsorName ?? ""}
                                </h3>
                                <p
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: 400,
                                    color: "#40499D",
                                    marginTop: "-3px",
                                  }}
                                >
                                  Sponsor
                                </p>
                              </Box>
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      )}

                    <img
                      src={`https://flagsapi.com/${
                        userInfo?.countryCode ?? "NG"
                      }/flat/32.png`}
                      alt="country"
                    />
                    {/* <NotificationsPopover /> */}

                    <AccountPopover />
                    {/* <AdminAccountPopover /> */}
                  </Stack>
                </Stack>
              </div>
            </Toolbar>
          </AppBar>
          <Main
            noPadding={
              location.pathname === "/dashboard/learning/study" ||
              location.pathname === "/dashboard/leaderboard"
            }
            open={open}
            className={`transition-all ease-in-out no-select ml-${
              open ? "0" : "240"
            }`}
            style={{
              overflowX: "hidden",
              backgroundColor: "#FAFAFB",
              minHeight: "98.8vh",
              paddingTop: paddingTopValue,
            }}
          >
            <DrawerHeader />
            {/* <section className="w-full bg-[#fafafb]">{children}</section> */}
            <section className="w-full ">{children}</section>
          </Main>
          <WeeklyMission
            open={openMissions}
            handleClose={handleCloseMissions}
            timeRemaining={timeRemaining}
          />
          <WeeklyTrivia open={openTrivia} handleClose={handleCloseTrivia} />
          <PremiumDialog open={trialModalOpen} handleClose={closeTrialModal} />
          <SwipeableDrawer
            anchor="left"
            open={drawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Sidebar
              open={open}
              setOpen={setOpen}
              openTrialModal={setTrialModalOpen}
            />
          </SwipeableDrawer>
        </Box>
      )}
    </>
  );
};

export default DashboardLayout;
