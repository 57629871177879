import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import Asynchronous from "../Autocomplete/CountryAsynchronous";
import { FormControl } from "@mui/base";
import ProfileUpdateForm from "../Forms/ProfileUpdateForm";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import useSettings from "../../../hooks/useSettings";
import { useDispatch } from "react-redux";
import { getCountries } from "../../../redux/settings/settingsActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function EditProfile({
  open,
  handleClose,
  nav,
  formValues,
  handleChange,
  handleSubmit,
  isLoading,
  handler,
  countryHandler,
  updateValue,
  values,
  updateStateValue,
  stateHandler,
  stateValues
}) {
  const { countries, countriesLoaded, isCountryLoading } = useSettings();

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {nav && nav === "name" ? "Edit Profile" : "Edit Personal Information"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <ProfileUpdateForm
            nav={nav}
            formValues={formValues}
            handleChange={handleChange}
            // allCountries={allCountries}
            handler={handler}
            updateValue={updateValue}
            countryHandler={countryHandler}
            values={values}
            updateStateValue={updateStateValue}
            stateHandler={stateHandler}
            stateValues={stateValues}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            autoFocus
            onClick={handleSubmit}
          >
            {isLoading && isLoading ? (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
