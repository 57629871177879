import React, { useEffect, useRef, useState } from 'react';
import {
  Grid, Typography, Box, Tabs, Tab, OutlinedInput,
  InputAdornment, IconButton, Chip, Menu, MenuItem,
  Button, Card, Dialog, FormControl,
  MenuList, ListItemIcon, ListItemText, DialogTitle, InputLabel, DialogContent, Paper, Avatar, DialogActions,
} from '@mui/material';
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import usePermissions from '../../../hooks/usePermissions';
import { BorderColor, DataUsage } from '@mui/icons-material';


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


const EditAdminUserDialog = (props) => {
  const { openBM, handleCloseBM, uploadedPicture, handleFileChange, uploading, input, setInput, handleSubmit, selectedRowAdmin } = props
  const { loading,adminRoles } = usePermissions();

  useEffect(() => {
    setInput({
      ...input,
      firstName: selectedRowAdmin.Firstname,
      lastName: selectedRowAdmin.Surname,
      middleName: selectedRowAdmin.OtherNames,
      email: selectedRowAdmin.Email,
      phoneNumber: selectedRowAdmin?.phoneNumber,
      role: selectedRowAdmin?.roleId
    })
  }, [selectedRowAdmin])

  const handleClose = () => {
    setInput({
      ...input,
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      phoneNumber: '',
      role: ''
    });
    handleCloseBM();
  }

  return (
    <Dialog
      open={openBM}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="alert-dialog-title"
      >
        <Typography sx={{
          color: '#313131',
          fontSize: '16px', fontWeight: 600,
          lineHeight: '16px'
        }}>Edit User</Typography>
        {/* <Typography
            sx={{
              color: '#919191',
              fontSize: '13px', fontStyle: 'normal', fontWeight: 400,
              lineHeight: '14px',
              mt: 1
            }}>A default code will be sent to the user's email to reset password on creation of account.</Typography> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "1px",
            right: "1px",
            justifyContent: "flex-end"
          }}

        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper variant="outlined" sx={{ p: 1 }}>
          <Grid item xs={12} mb={3}>
            <InputLabel htmlFor="profilePicture" mb={4}>
              Profile Picture
            </InputLabel>
            <FormControl fullWidth>
              {!uploadedPicture && (
                <Box
                  component="label"
                  sx={{
                    border: "1.5px dashed #E2E2E2",
                    borderSpacing: "20px",
                    borderRadius: "8px",
                    padding: "16px 12px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#F2F4FE ", // Light purple color on hover
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: "48px",
                      height: "48px",
                      border: "1px solid #ccc",
                    }}
                  />
                  <Grid>
                    <Typography sx={{ fontSize: "16px" }}>
                      {uploading ? (
                        "Uploading..."
                      ) : (
                        <>
                          {" "}
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "#40499D",
                              fontWeight: 500,
                            }}
                          >
                            Select a profile picture
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "#919191" }}
                          >
                            Supports: JPG, JPEG and PNG
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </Grid>

                  <VisuallyHiddenInput
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: "none" }} // Hide the input visually
                    onChange={handleFileChange}
                    disabled={uploading}
                  />
                </Box>
              )}
              {uploadedPicture && (
                <Grid
                  component="label"
                  sx={{
                    border: "2px dashed #E2E2E2",
                    borderSpacing: "20px",
                    borderRadius: "8px",
                    padding: "16px 12px",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <Avatar
                    src={uploadedPicture.base64String}
                    alt="Uploaded"
                    sx={{
                      width: "48px",
                      height: "48px",
                      border: "1px solid #ccc",
                    }}
                  />

                  {/* <Grid item>
                          <Typography>{uploadedPicture.file.name}</Typography>
                        </Grid> */}
                  <Grid>
                    <Typography sx={{ fontSize: "16px" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#40499D",
                          fontWeight: "bold",
                          paddingBottom: "4px ",
                        }}
                      >
                        {uploadedPicture.file.name}
                      </Typography>
                      <Button
                        variant="outlined"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                        disabled={uploading}
                        sx={{ fontSize: "10px", color: "#919191" }}
                      >
                        Change
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </FormControl>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>

              <input style={{ marginTop: '.8rem' }}
                className="input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Last Name" onChange={(e) => setInput({ ...input, lastName: e.target.value })} value={input.lastName} disabled />
            </Grid>
            <Grid item xs={6}>

              <input style={{ marginTop: '.8rem' }}
                className="input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="First Name" onChange={(e) => setInput({ ...input, firstName: e.target.value })} value={input.firstName} disabled />

            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>

              <input style={{ marginTop: '.8rem' }}
                className="input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Middle Name" onChange={(e) => setInput({ ...input, middleName: e.target.value })} value={input.middleName} disabled />
            </Grid>
            <Grid item xs={6}>

              <input style={{ marginTop: '.8rem' }}
                className="input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Phone Number" onChange={(e) => setInput({ ...input, phoneNumber: e.target.value })} value={input.phoneNumber} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>

              <input style={{ marginTop: '.8rem' }}
                className="input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Email" onChange={(e) => setInput({ ...input, email: e.target.value })} value={input.email} />
            </Grid>

          </Grid>
          <Grid container spacing={1} className='mt-3'>

              <Grid item xs={12} className='mt-4'>
                <select
                  name="role"
                  id="role"
                  // labelId="demo-select-small-label"
                  value={input.role}
                  onChange={(e) => setInput({ ...input, role: e.target.value })}
                  className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border mt-4"
                  disabled={loading}
                  required
                >
                  <option value="">Select Role*</option>
                  {adminRoles?.map((option, i) => (
                    <option key={option.roleId} value={option.roleId}>
                      {option.roleName}
                    </option>
                  ))}
                </select>

              </Grid>
            </Grid>

        </Paper>
      </DialogContent>
      <DialogActions>
        <button onClick={() => { handleClose(); handleSubmit() }} style={{ backgroundColor: '#40499D' }} className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" disabled={loading}>
          {!loading ? (
            <Typography sx={{
              color: '#fff',
              fontSize: '12px', fontWeight: 600,
              lineHeight: '16.8px',
              fontFamily: 'Open Sans'
            }}>Edit User</Typography>
          ) : (
            <DataUsage size={24} className="animate-spin ml-4" />
          )}

        </button>
      </DialogActions>
    </Dialog>
  )
}

export default EditAdminUserDialog