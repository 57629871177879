import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as WeeklyTriviaBanner } from "../../../assets/images/WeeklyTriviaBanner.svg";

const TitleBar = () => {
  
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      color={"white"}
      alignItems={"center"}
      gap={9}
    >
      <Stack
        sx={{ width: "175px"}}
      >
        <Typography fontSize={"22px"} fontWeight={700} lineHeight={"normal"}>
          Week 2
        </Typography>
      </Stack>
      <Box>
        <WeeklyTriviaBanner />
      </Box>
      <Stack
        sx={{ width: "175px",}}
      >
        <Typography
          fontSize={"14px"}
          fontWeight={600}
          sx={{
            textDecoration: "underline",
            textAlign: "right",
            color: "#F8EF07",
          }}
        >
          View leaderboard
        </Typography>
        <Typography fontSize={"14px"} fontWeight={400}>
          Refreshes in 04d 08h 23m
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TitleBar;
