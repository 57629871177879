import axios from "axios";
import store from "../redux/store";
import { jwtDecode } from "jwt-decode";
import { createBrowserHistory } from "history";
import { logoutUserAction } from "../redux/auth/authActions";
import { logoutAdminUserAction } from "../redux/adminAuth/adminAuthActions";

// Get user details
const userDetails = () => {
  let authState = store.getState().auth;
  return authState;
};

const adminDetails = () => {
  let adminAuthState = store.getState().adminAuth;
  return adminAuthState;
};

let history = createBrowserHistory({ forceRefresh: true });

// Set Base Url
// const baseUrl = process.env.NODE_ENV !== "development" ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

const baseUrl = (window.location.origin == 'https://www.estudy.ng' || window.location.origin == 'https://estudy.ng' || window.location.origin == 'https://waec.estudy.ng' || window.location.origin == 'https://estudyapp.azurewebsites.net/') ? 'https://estudy.ng/api' : 'https://estudyapp-dev.azurewebsites.net/api';

const token = userDetails()?.token ? userDetails()?.token : adminDetails()?.token;

const instance = axios.create({
  baseURL: baseUrl, // Your API base URL
  timeout: 100000, // Request timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    // "Authorization": "Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJjNzZhMjIyZS05OWFkLTQ3YWUtYTQ3OC04MTk2OGYyMjZkNmMiLCJ1bmlxdWVfbmFtZSI6Ik1vc2VzIEFrb21vbGFmZSIsImVtYWlsIjoiZ21ha29tb2xhZmVAc2lkbWFjaC5jb20iLCJyb2xlIjoiNTI3YTVmNjEtZDM2Mi00YTgyLTkyMDctNmQ3Yzc3NDBlMTY4IiwibmJmIjoxNjkyNjk1MjAxLCJleHAiOjE2OTI3ODE2MDEsImlhdCI6MTY5MjY5NTIwMX0.mwKo6EyBCWU1INNLI0IJX_2QvUruT4rPSjRHW2iIKsazE2heFCOFOSjwNC5P5eNWxT_p-08woHK5EaMYxDHseQ",
    // You can add any other default headers here
  },
  withCredentials: false, //
});

// Logout user
const logoutUser = async (payLoad) => {
  return axios
    .post(`${baseUrl}/Access/SignOut`, payLoad)
    .then((data) => {
      store.dispatch(logoutUserLocallyAction());
      return data;
    })
    .catch((error) => {
      store.dispatch(logoutUserLocallyAction());
      return error.response;
    });
};

// Handle success response
const successResponseHandler = (response) => {
  //console.log("response", response);

  // No content response (204)
  if (response.status === 204) {
    response.data = { data: {} };
  }

  // Log response
  if (process.env.REACT_APP_APP_ENV === "development") {
    // console.log('Success Response From Connection', response.data);
  }

  // Additional checks for API that does not utilize the HTTP status code properly
  if (response.data.status === false || response.data.status === "failed") {
    if (response.data.error && (response.data.error.code === 401 || response.data.message == "You have been logged out of this session")) {
      // Logout user
      store.dispatch(adminDetails()?.token ? logoutAdminUserAction(token) : logoutUserAction(token));
      return Promise.reject(response.data.error);
    }

    // Error message is retrieved from the JSON body.
    const error = new Error(response.data.message);

    // Attach the response instance, in case you decide to access it.
    error.response = response;

    throw error;
  }

  // Return processed response
  return response;
};

// Handle failure response
const failureResponseHandler = async (error) => {
  //console.log(error, "error");
  //
  if (error.response && (error.response.status === 401 || error.response.data.state == -2)) {
    // Token expired, log out the user
    store.dispatch(adminDetails()?.token ? logoutAdminUserAction(token) : logoutUserAction(token));
    return Promise.reject(error);
  }
  // Log error response
  if (process.env.REACT_APP_APP_ENV === "development") {
    // console.log('Failure Response From Connection', error);
  }

  // No network response (ECONNABORTED)
  if (!error.response || error.code === "ECONNABORTED") {
    // alert(`Could not connect to network`);
    return Promise.reject(error);
  }

  // Return unprocessed error
  return Promise.reject(error);
};

// Add a request interceptor
instance.interceptors.request.use((req) => {
  const token = userDetails()?.token ? userDetails()?.token : adminDetails()?.token;

  if (baseUrl.includes("testing")) {
    // Modify this condition to allow other environments
    req.headers["x-platform"] = "web";
  }
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  // Reattach the base url
  if (!req.baseURL) {
    req.url = baseUrl + "/" + req.url;
  }

  // Log requests
  if (process.env.REACT_APP_APP_ENV === "development") {
    //console.log("Request", req);
  }

  return req;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return successResponseHandler(response);
  },
  (error) => {
    return failureResponseHandler(error);
  }
);

export default instance;
