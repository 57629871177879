export const ADD_SUBJECT_BY_PROFILE_ID = "ADD_SUBJECT_BY_PROFILE_ID";
export const ADD_SUBJECT_BY_PROFILE_ID_SUCCESS =
  "ADD_SUBJECT_BY_PROFILE_ID_SUCCESS";
export const ADD_SUBJECT_BY_PROFILE_ID_ERROR =
  "ADD_SUBJECT_BY_PROFILE_ID_ERROR";

export const UPDATE_SUBJECT_BY_PROFILE_ID = "UPDATE_SUBJECT_BY_PROFILE_ID";
export const UPDATE_SUBJECT_BY_PROFILE_ID_SUCCESS =
  "UPDATE_SUBJECT_BY_PROFILE_ID_SUCCESS";
export const UPDATE_SUBJECT_BY_PROFILE_ID_ERROR =
  "UPDATE_SUBJECT_BY_PROFILE_ID_ERROR";

export const FETCH_STUDENT_DASHBOARD_STAT = "FETCH_STUDENT_DASHBOARD_STAT";
export const FETCH_STUDENT_DASHBOARD_STAT_SUCCESS =
  "FETCH_STUDENT_DASHBOARD_STAT_SUCCESS";
export const FETCH_STUDENT_DASHBOARD_STAT_ERROR =
  "FETCH_STUDENT_DASHBOARD_STAT_ERROR";

export const FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT =
  "FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT";
export const FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_SUCCESS =
  "FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_SUCCESS";
export const FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_ERROR =
  "FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_ERROR";

export const FETCH_STUDIES_IN_PROGRESS_STAT = "FETCH_STUDIES_IN_PROGRESS_STAT";
export const FETCH_STUDIES_IN_PROGRESS_STAT_SUCCESS =
  "FETCH_STUDIES_IN_PROGRESS_STAT_SUCCESS";
export const FETCH_STUDIES_IN_PROGRESS_STAT_ERROR =
  "FETCH_STUDIES_IN_PROGRESS_STAT_ERROR";

export const FETCH_STUDY_TIME = "FETCH_STUDY_TIME";
export const FETCH_STUDY_TIME_SUCCESS = "FETCH_STUDY_TIME_SUCCESS";
export const FETCH_STUDY_TIME_ERROR = "FETCH_STUDY_TIME_ERROR";

export const FETCH_ADMIN_DASHBOARD_STAT = "FETCH_ADMIN_DASHBOARD_STAT";
export const FETCH_ADMIN_DASHBOARD_STAT_SUCCESS =
  "FETCH_ADMIN_DASHBOARD_STAT_SUCCESS";
export const FETCH_ADMIN_DASHBOARD_STAT_ERROR =
  "FETCH_ADMIN_DASHBOARD_STAT_ERROR";

export const FETCH_ADMIN_DASHBOARD_CHART = "FETCH_ADMIN_DASHBOARD_CHART";
export const FETCH_ADMIN_DASHBOARD_CHART_SUCCESS =
  "FETCH_ADMIN_DASHBOARD_CHART_SUCCESS";
export const FETCH_ADMIN_DASHBOARD_CHART_ERROR =
  "FETCH_ADMIN_DASHBOARD_CHART_ERROR";

export const FETCH_ADMIN_DASHBOARD_REG_USER_CHART =
  "FETCH_ADMIN_DASHBOARD_REG_USER_CHART";
export const FETCH_ADMIN_DASHBOARD_REG_USER_CHART_SUCCESS =
  "FETCH_ADMIN_DASHBOARD_REG_USER_CHART_SUCCESS";
export const FETCH_ADMIN_DASHBOARD_REG_USER_CHART_ERROR =
  "FETCH_ADMIN_DASHBOARD_REG_USER_CHART_ERROR";

export const ALL_AMITTED_YEAR_FAIL = "ALL_AMITTED_YEAR_FAIL";
export const ALL_AMITTED_YEAR = "ALL_AMITTED_YEAR";
export const ALL_AMITTED_YEAR_SUCCESS = "ALL_AMITTED_YEAR_SUCCESS";

export const FETCH_STUDENT_LEARNING = "FETCH_STUDENT_LEARNING";
export const FETCH_STUDENT_LEARNING_SUCCESS = "FETCH_STUDENT_LEARNING_SUCCESS";
export const FETCH_STUDENT_LEARNING_ERROR = "FETCH_STUDENT_LEARNING_ERROR";

export const FETCH_TEST_DETAILS = "FETCH_TEST_DETAILS";
export const FETCH_TEST_DETAILS_SUCCESS = "FETCH_TEST_DETAILS_SUCCESS";
export const FETCH_TEST_DETAILS_ERROR = "FETCH_TEST_DETAILS_ERROR";

export const FETCH_SCHOOL_DASHBOARD_STAT = "FETCH_SCHOOL_DASHBOARD_STAT";
export const FETCH_SCHOOL_DASHBOARD_STAT_SUCCESS =
  "FETCH_SCHOOL_DASHBOARD_STAT_SUCCESS";
export const FETCH_SCHOOL_DASHBOARD_STAT_ERROR =
  "FETCH_SCHOOL_DASHBOARD_STAT_ERROR";

export const FETCH_TOP_STUDENTS = "FETCH_TOP_STUDENTS";
export const FETCH_TOP_STUDENTS_SUCCESS = "FETCH_TOP_STUDENTS_SUCCESS";
export const FETCH_TOP_STUDENTS_ERROR = "FETCH_TOP_STUDENTS_ERROR";

export const FETCH_STUDENTS_PATH = "FETCH_STUDENTS_PATH";
export const FETCH_STUDENTS_PATH_SUCCESS = "FETCH_STUDENTS_PATH_SUCCESS";
export const FETCH_STUDENTS_PATH_ERROR = "FETCH_STUDENTS_PATH_ERROR";

export const FETCH_SCHOOL_STUDY_TIME = "FETCH_SCHOOL_STUDY_TIME";
export const FETCH_SCHOOL_STUDY_TIME_SUCCESS =
  "FETCH_SCHOOL_STUDY_TIME_SUCCESS";
export const FETCH_SCHOOL_STUDY_TIME_ERROR = "FETCH_SCHOOL_STUDY_TIME_ERROR";

export const FETCH_STUDENTS_SUBJECTS = "FETCH_STUDENTS_SUBJECTS";
export const FETCH_STUDENTS_SUBJECTS_SUCCESS =
  "FETCH_STUDENTS_SUBJECTS_SUCCESS";
export const FETCH_STUDENTS_SUBJECTS_ERROR = "FETCH_STUDENTS_SUBJECTS_ERROR";

export const FETCH_AMOUNT_DETAILS = "FETCH_AMOUNT_DETAILS";
export const FETCH_AMOUNT_DETAILS_SUCCESS = "FETCH_AMOUNT_DETAILS_SUCCESS";
export const FETCH_AMOUNT_DETAILS_ERROR = "FETCH_AMOUNT_DETAILS_ERROR";

export const FETCH_SPONSOR_DASHBOARD_STAT = "FETCH_SPONSOR_DASHBOARD_STAT";
export const FETCH_SPONSOR_DASHBOARD_STAT_SUCCESS =
  "FETCH_SPONSOR_DASHBOARD_STAT_SUCCESS";
export const FETCH_SPONSOR_DASHBOARD_STAT_ERROR =
  "FETCH_SPONSOR_DASHBOARD_STAT_ERROR";

export const FETCH_SPONSOR_STUDENTS_PATH = "FETCH_SPONSOR_STUDENTS_PATH";
export const FETCH_SPONSOR_STUDENTS_PATH_SUCCESS =
  "FETCH_SPONSOR_STUDENTS_PATH_SUCCESS";
export const FETCH_SPONSOR_STUDENTS_PATH_ERROR =
  "FETCH_SPONSOR_STUDENTS_PATH_ERROR";

export const FETCH_SPONSOR_BENEFICIARIES = "FETCH_SPONSOR_BENEFICIARIES";
export const FETCH_SPONSOR_BENEFICIARIES_SUCCESS =
  "FETCH_SPONSOR_BENEFICIARIES_SUCCESS";
export const FETCH_SPONSOR_BENEFICIARIES_ERROR =
  "FETCH_SPONSOR_BENEFICIARIES_ERROR";

//Leaderboard Types
export const FETCH_LEADERBOARD_LADDER = "FETCH_LEADERBOARD_LADDER";
export const FETCH_LEADERBOARD_LADDER_SUCCESS =
  "FETCH_LEADERBOARD_LADDER_SUCCESS";
export const FETCH_LEADERBOARD_LADDER_ERROR = "FETCH_LEADERBOARD_LADDER_ERROR";

export const GET_WEEKLY_TRIVIA = "GET_WEEKLY_TRIVIA";
