import {
  GET_LEADERBOARD_LOADING,
  GET_LEADERBOARD_FAIL,
  GET_LEADERBOARD_SUCCESS,
} from "./types";

const initialState = {
  loading: false,
  gamificationData: [],
  error: null,
};

const gamificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        gamificationData: action.payload,
        error: null,
      };
    case GET_LEADERBOARD_FAIL:
      return {
        ...state,
        loading: false,
        gamificationData: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default gamificationReducer;
