import { Box, Button, Stack, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Answers = styled(Button)(({ theme, bgcolor }) => ({
  flexBasis: "calc(50% - 10px)",
  fontSize: "20px",
  border: "5px solid #FFD2AF",
  backgroundColor: bgcolor || theme.palette.background.paper,
  color: bgcolor ? theme.palette.getContrastText(bgcolor) : theme.palette.text.primary,
  fontWeight: 900,
  // color: "#000",
  borderRadius: "16px",
  boxSizing: "border-box",
  textTransform: 'capitalize',
  "&:hover": {
    border: "5px solid #FFD2AF",
    backgroundColor: bgcolor ? bgcolor : theme.palette.action.hover,
  },
}));

const NextBtn = styled(Button)(({ theme }) => ({
  backgroundColor: "#02A502",
  color: "#FFFDFD",
  textTransform: "inherit",
  fontSize: "20px",
  fontWeight: 800,
  "&:hover": {
    color: "#FFFDFD",
    backgroundColor: "#02A502",
  },
}));


const AnswerOptions = ({ testData, handleAnswerSelect, handleNextQuestion, options, selectedButton, currentNum, updateOptions, handleSubmit, totalTrivaNumber }) => {


  useEffect(() => {
    if (testData?.length > 0 && testData[currentNum]?.choices) {
      const newOptions = testData[currentNum].choices.map((choice, index) => ({
        optionText: choice.option,
        optionNo: choice.optionNo,
        index: index,
        // bgcolor: "#FFFAF6"  // Default background color
      }));
      updateOptions(newOptions);
    }
  }, [testData, currentNum, updateOptions]);



  const numbers = totalTrivaNumber - 1;
  return (
    <Box>
      <Stack
        mt={2}
        width={"100%"}
        direction="row"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={2}
      >
        {options?.map((x, i) => {
          debugger
          return (
            <Answers
              key={x.optionNo}
              onClick={() => handleAnswerSelect(x.optionNo, i)}
              bgcolor={x.bgcolor}
              disabled={selectedButton !== null}
              style={{
                // backgroundColor: x.bgcolor,
                fontSize: x.optionText?.length > 10 ? '16px' : '20px'
              }}
            // sx={{
            //   // color: x.bgcolor === "#008000" || x.bgcolor === "#FF0000" ? "#008000" : "inherit",
            //   fontSize: x.optionText?.length > 10 ? '16px' : '20px'
            // }}
            >
              {typeof x.optionText === 'string' ? window.HTMLReactParser(x.optionText) : x.optionText}
              {/* {x.optionText} */}
            </Answers>
          )
        })}
      </Stack>
      <Stack direction={"row"} justifyContent={"center"} p={2}>
        {/* {currentNum < testData[0]?.noOfQns ? */}
        {currentNum < numbers ?
          <NextBtn
            onClick={() => handleNextQuestion()}
            disabled={selectedButton === null}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            Next
          </NextBtn>
          : <NextBtn
            onClick={() => handleSubmit()}
            disabled={selectedButton === null}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            Finish
          </NextBtn>}
      </Stack>
    </Box>
  );
};

export default AnswerOptions;
